import Intents from '../tabs/nlp/components/Intents'
import React from 'react'
import Triggers from '../tabs/nlp/components/Triggers'
import TrainingContainer from '../tabs/nlp/components/TrainingContainer'

export const nlpRoutes = [
  {
    path: 'training',
    name: 'Training',
    component: () => <TrainingContainer/>,
  },
  {
    path: 'faq',
    name: 'FAQ',
    component: () => (
      <Intents isFaq={true} tableTitle="FAQ" createButton="Create FAQ" />
    ),
  },
  {
    path: 'intents',
    name: 'Intents',
    component: () => (
      <Intents
        isFaq={false}
        tableTitle="Intents"
        createButton="Create Intent"
      />
    ),
  },
  {
    path: 'triggers',
    name: 'Triggers',
    component: () => <Triggers/>,
  },
]

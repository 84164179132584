import React, { useEffect, useState } from 'react'
import moment from 'moment'

import TrainButton from '../TrainButton'
import Table from 'uiKit/table/Table'
import TableRow from 'uiKit/table/TableRow'
import TableCell from 'uiKit/table/TableCell'
import IntentSelect from '../IntentSelect'
import { ExternalLinkIcon } from 'uiKit/icons/ExternalLinkIcon'
import { deepCopyFunction } from 'helpers/deepCopyFunction'
import { trainIntent } from '../../api/nlp'
import { allTabTitles, notHandledTabTitles } from '../../constants/tableTitles'
import { ALL } from '../../constants/tabs'
import { HOME_PATH } from 'configs'

import * as S from './TrainingTable.style'

interface Props {
  activeBot?: any
  nlpRequests?: any
  tab: string
}

export const TrainingTable: React.FC<Props> = ({ nlpRequests, tab, activeBot }) => {
  const [requests, setRequests] = useState(deepCopyFunction(nlpRequests.data))

  useEffect(() => {
    setRequests(deepCopyFunction(nlpRequests.data))
  }, [nlpRequests.data])

  const handleSelectIntent = (option, request) => {
    const newRequests = [...requests]?.map(req => {
      if (req.id === request.id) {
        req.triggeredIntentId = option.value
        req.triggeredIntentName = option.label
        req.trained = false
      }
      return req
    })

    setRequests(newRequests)
  }

  const handleTrainRequest = request => {
    trainIntent(request, activeBot.id)
  }

  const handleOpenLink = userId => {
    const link = `${HOME_PATH}/bot/${activeBot.id}/support/${userId}`
    window.open(link, '_blank')
  }

  return (
    <Table titles={tab === ALL ? allTabTitles : notHandledTabTitles}>
      {requests?.map((nlpRequest, index) => (
        <TableRow key={nlpRequest.id} index={index}>
          <TableCell style={{ maxWidth: 200 }}>{nlpRequest.userSay}</TableCell>
          <TableCell style={{ maxWidth: 30 }}>
            <S.ExternalLinkWrap onClick={() => handleOpenLink(nlpRequest.userId)}>
              <ExternalLinkIcon />
            </S.ExternalLinkWrap>
          </TableCell>
          <TableCell style={{ maxWidth: 190 }}>
            {nlpRequest.trained ? (
              nlpRequest.triggeredIntentName
            ) : (
              <S.SelectorWrap>
                <IntentSelect
                  value={{ value: nlpRequest.triggeredIntentId }}
                  onChange={option => handleSelectIntent(option, nlpRequest)}
                />
              </S.SelectorWrap>
            )}
          </TableCell>
          <TableCell style={{ maxWidth: 80 }}>
            <TrainButton onClick={() => handleTrainRequest(nlpRequest)} isTrained={nlpRequest.trained} />
          </TableCell>
          {tab === ALL && (
            <TableCell style={{ maxWidth: 80 }}>
              {Number.parseFloat(nlpRequest.detectionConfidence).toFixed(2)?.replace(/\.0+$/, '')}
            </TableCell>
          )}
          <TableCell style={{ maxWidth: 120 }}>
            {moment(nlpRequest.createdDateTime).format('DD.MM.YY HH:mm A')}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}

import React, { useEffect, useMemo, useState } from 'react'
import Select, { components } from 'react-select'
import ReactTooltip from 'react-tooltip'
import DetectableOverflow from 'react-detectable-overflow'

import SelectorStyles from '../../constants/selectorStyles'
import { IntentType } from 'models/IntentsType'
import { OptionItemType } from 'models/MessageTypes'
import classes from 'uiKit/AtomSelect/styles.module.scss'

interface Props {
  intents: IntentType[]
  value: OptionItemType
  onChange: (v: OptionItemType) => void
  defaultValue?: any
  isLarge?: boolean
}

export const IntentSelect: React.FC<Props> = ({ intents, value, onChange, defaultValue }) => {
  const [options, setOptions] = useState([])
  const [isOverflowed, setIsOverflowed]: [boolean, any] = useState(false)

  const selectedValue = useMemo(
    () => options?.find(option => option?.value === value?.value || option?.label === value?.label),
    [value, options],
  )

  useEffect(() => {
    const mappedIntents = intents.map(intent => ({ value: intent.id, label: intent.name }))

    if (defaultValue) {
      mappedIntents.unshift(defaultValue)
    }

    setOptions(mappedIntents)
  }, [intents])

  const SingleValue = props => {
    return (
      <components.SingleValue {...props}>
        <DetectableOverflow onChange={handleOverflow}>{props.children}</DetectableOverflow>
      </components.SingleValue>
    )
  }

  const handleOverflow = isOverflowed => {
    setIsOverflowed(isOverflowed)
  }

  const handleChange = value => {
    setIsOverflowed(false)
    onChange(value)
  }

  return (
    <span data-tip={selectedValue?.label} data-for={selectedValue?.label}>
      <Select
        styles={SelectorStyles}
        options={options}
        value={selectedValue}
        onChange={handleChange}
        components={{ SingleValue }}
      />
      {isOverflowed && selectedValue?.label && (
        <ReactTooltip
          className={classes.tooltip}
          effect="solid"
          place="left"
          type="dark"
          scrollHide={true}
          id={selectedValue?.label}
        />
      )}
    </span>
  )
}

import React from 'react'
import TabTemplate from '../../../../../uiKit/TabTemplate'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import FacebookIcon from '../../../../../uiKit/icons/FacebookIcon'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { exchangeShortLiveUserTokenToLongLive, getFacebookAppId, getFacebookPages } from '../../../api/facebook'

import { NewPage } from './NewPage'
import { FacebookActivePages } from './FacebookActivePages/FacebookActivePages'
import SubmitButton from '../../../../../uiKit/buttons/SubmitButton'
import { getBot } from '../../../../home/api/bots'
import { saveFacebookToken } from '../../../actions/facebookToken'
import { alertError } from '../../../../../api'
import Modal from '../../../../../uiKit/Modal'
import LoaderSmall from '../../../../../uiKit/loaders/loaderSmall'
import { getFacebookLoggedIn, saveFacebookLoggedIn } from './FacebookConfig'

const styles = theme => ({
  title: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: theme.palette.text.header,
  },
  color: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: theme.palette.text.header,
  },
  rootRadio: {
    marginLeft: 13,
    color: theme.palette.primary.iconsGray,
    fontSize: 14,
    fontFamily: 'Lato',
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  pageItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #EBEBEB',
    padding: '16px 0',
    '&:first-of-type': {
      paddingTop: 3, //3px coz it should be 24px according to the design, tab-top itself has 21
    },
    '&:last-of-type': {
      borderBottom: 'none',
      // paddingBottom: 21,           //uncomment this after adding pagination to the pages
      paddingBottom: 0,
    },
  },
  pageInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  pageImg: {
    width: '40px',
    height: '40px',
    marginRight: '16px',
    borderRadius: 100,
  },
  pageName: {
    color: '#3A3F62',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: '500',
    fontFamily: 'Lato, "sans-serif"',
  },
  connectedStatus: {
    fontSize: '14px',
    fontFamily: 'Lato, "sans-serif"',
    fontWeight: '500',
    color: '#43C692',
    marginRight: '24px',
  },
  connectedAnother: {
    fontSize: '14px',
    fontFamily: 'Lato, "sans-serif"',
    fontWeight: '500',
    color: '#3A3F62',
    marginRight: '24px',
  },
  wrapPagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    // borderBottom:`1px solid ${theme.palette.primary.grayColor}`
  },
  checked: {},
  '@global': {
    '.rootRadio label': {
      margin: '-4px 16px -4px -14px',
    },
    '.rootRadio span[class*="label"]': {
      marginLeft: 8,
    },
    '.rootRadio span': {
      fontSize: 14,
      fontFamily: 'Lato',
    },
  },
  pageModalPaper: {
    padding: '24px',
    maxWidth: 360,
    width: '100%',

    '& h2': {
      maxWidth: 288,
      textAlign: 'start',
    },
  },
})

class Facebook extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 0,
      facebookAppId: null,
      rowsPerPage: 10,
      isOpenNewPageModal: false,
      isLoading: false,
    }
  }

  componentDidMount() {
    const facebookLoggedIn = getFacebookLoggedIn()
    this.setState(
      {
        isLoading: true,
      },
      () => {
        getFacebookAppId().then(response => {
          this.setState({
            facebookLoggedIn: facebookLoggedIn,
            facebookAppId: response.appId,
            isLoading: false,
          })
        })
      },
    )
    getBot(this.props.activeBot.id).then()
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  closeNewPageModalHandle = () => {
    this.setState({ isOpenNewPageModal: false })
  }

  openNewPageModalHandle = () => {
    this.setState({ isOpenNewPageModal: true })
  }

  processFacebookCredentialsAndGetFacebookPages = userFacebookAccessToken => {
    saveFacebookToken(userFacebookAccessToken)
    saveFacebookLoggedIn(true)
    getFacebookPages(this.props.match.params.botId, userFacebookAccessToken, () => this.setState({ isLoading: false }))
  }

  processUserAccessToken = accessToken => {
    if (accessToken) {
      this.setState({
        isLoading: true,
      })
      exchangeShortLiveUserTokenToLongLive(accessToken)
        .then(response => {
          this.processFacebookCredentialsAndGetFacebookPages(response.accessToken)
        })
        .catch(error => {
          alertError('Sorry but something going wrong please ping support!')

          this.setState({
            isLoading: false,
          })
        })
      this.setState({
        isLoading: false,
      })
    }
  }

  getConnectButton = () => {
    if (this.props.settingsPages.facebookPages) {
      return <SubmitButton size="sm" title="Connect to Facebook page" onClick={() => this.openNewPageModalHandle()} />
    } else if (this.state.facebookAppId) {
      return (
        <FacebookLogin
          appId={this.state.facebookAppId}
          autoLoad={!!this.state.facebookLoggedIn}
          callback={({ accessToken }) => this.processUserAccessToken(accessToken)}
          scope="manage_pages,pages_messaging"
          render={renderProps => (
            <SubmitButton title="Connect to Facebook page" size="sm" onClick={renderProps.onClick} />
          )}
        />
      )
    }
  }

  render() {
    const { classes, derivedClasses, settingsPages, expanded, handleChangeExpansion } = this.props
    const { isLoading } = this.state

    return (
      <div>
        <TabTemplate
          tab={{
            name: 'Facebook',
            icon: <FacebookIcon />,
            description: 'Connect the chatbot to your Facebook page',
          }}
          button={this.getConnectButton()}
          expanded={expanded}
          onChange={handleChangeExpansion}>
          <div className={derivedClasses.container}>
            {isLoading && <LoaderSmall showLoader />}
            {!isLoading && <FacebookActivePages fbToken={this.props.fbToken} botId={this.props.match.params.botId} />}
          </div>

          <Modal
            classes={{
              paper: classes.pageModalPaper,
            }}
            open={this.state.isOpenNewPageModal}
            onClose={this.closeNewPageModalHandle}
            title="Connect chatbot to Facebook page"
            customStyle={{ height: '100%', maxHeight: '550px' }}>
            <NewPage
              botId={this.props.match.params.botId}
              pages={settingsPages.facebookPages}
              fbToken={this.props.fbToken}
              closeNewPageModalHandle={this.closeNewPageModalHandle}
            />
          </Modal>
        </TabTemplate>
      </div>
    )
  }
}

Facebook.defaultProps = {
  classes: {},
}

Facebook.propTypes = {
  classes: PropTypes.object,
  settingsPages: PropTypes.object,
}

const mapStateToProps = state => ({
  settingsPages: state.settingsPages,
  activeBot: state.activeBot,
  fbToken: state.token,
})

export default withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(Facebook)))

import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import LoaderScreen from 'uiKit/loaders/loaderScreen'
import Headline from 'uiKit/texts/Headline'
import Submenu from 'uiKit/submenu/Submenu'
import DateControl from 'uiKit/DateControlNew'
import EmptyNlpScreen from './components/EmptyNlpScreen'

import { nlpRoutes } from 'routes/nlpRoutes'
import { HOME_PATH } from 'configs'
import { DateControlProvider } from 'contexts/DateControlContext'
import { getFlows } from '../flowBuilder/api/flow'
import isTrainingSubTab from 'helpers/isTrainingSubTab'

import * as S from './NlpContainer.style'

interface Props {
  activeBot: any
  match: any
}

const NlpContainer: React.FC<Props> = ({ activeBot, match }) => {
  const [loader, setLoader] = useState(true)
  const pathLink = `${HOME_PATH}/bot/${activeBot?.id}/nlp`
  const pathRoute = `${HOME_PATH}/bot/:botId/nlp/`

  useEffect(() => {
    getFlows(match.params.botId)
    setLoader(false)
  }, [])

  return (
    <S.Container>
      {loader && <LoaderScreen />}

      {!loader && (
        <DateControlProvider>
          <S.HeadlineWrap>
            <Headline title="NLP Training" />
            {activeBot?.nlpEnabled && isTrainingSubTab() && <DateControl />}
          </S.HeadlineWrap>
          {activeBot?.nlpEnabled ? (
            <Submenu pathLink={pathLink} pathRoute={pathRoute} routes={nlpRoutes} />
          ) : (
            <EmptyNlpScreen />
          )}
        </DateControlProvider>
      )}
    </S.Container>
  )
}

const mapStateToProps = (state: { activeBot: any }) => ({
  activeBot: state.activeBot,
})

export default withRouter(connect(mapStateToProps)(NlpContainer))

import React, { ReactElement } from 'react'
import Modal from '@material-ui/core/Modal'
import SubmitButton from '../buttons/SubmitButton'
import { CrossIcon } from '../icons/CrossIcon'
import { SPUN_PEARL_GREY } from '../../constants/colors'

import classes from './styles.module.scss'

interface Props {
  open: boolean
  onClose: any
  onSave: any
  title: string
  children: ReactElement
  customFooter?: ReactElement
}

const ModalTemplate: React.FC<Props> = ({ open, onClose, onSave, title, children, customFooter }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.header}>
          <p className={classes.title}>{title}</p>
          <div className={classes.closeButton} onClick={onClose}>
            <CrossIcon color={SPUN_PEARL_GREY} />
          </div>
        </div>
        {children}
        <div className={classes.footer}>
          <div className={classes.buttons}>
            <SubmitButton title='Cancel' type='secondary' styles={{ borderRadius: 4 }} onClick={onClose} />
            <SubmitButton title='Save' styles={{ borderRadius: 4 }} onClick={onSave} />`
          </div>
          {customFooter}
        </div>
      </div>
    </Modal>
  )
}

export default ModalTemplate

import { saveFacebookPages } from '../actions/settingsPages'
import {
  alertError,
  createJsonHeaders, customResponseParser,
  logoutOnRequestOrResponseJson,
} from '../../../api'
import { BASE_URL } from '../../../configs'

export function getFacebookPages(botId, accessToken, callback, token) {
  return fetch(
    // eslint-disable-next-line max-len
    `https://graph.facebook.com/v3.1/me?access_token=${accessToken}&fields=accounts{id,access_token,name,picture{url},is_webhooks_subscribed}`,
    {
      credentials: 'same-origin',
      headers: createJsonHeaders(token),
      method: 'GET',
    },
  )
    .then(response => {
      if (response.ok)
        return response.redirected
          ? (window.location.href = response.url)
          : response.json()
    })
    .then(json => {
      saveFacebookPages(json.accounts.data)
    })
    .then(() => {
      if (callback) callback()
    })
    .catch(function(error) {
      alertError('Pages displaying error')
    })
}

export function getFacebookAppId() {
  return fetch(`${BASE_URL}/configs/facebook`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  }).then(response => logoutOnRequestOrResponseJson(response))
}

export function exchangeShortLiveUserTokenToLongLive(accessToken) {
  return fetch(
    `${BASE_URL}/facebook/exchange/token?accessToken=${accessToken}`,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET',
    },
  ).then(response => {
    if (response.ok)
      return response.redirected
        ? (window.location.href = response.url)
        : response.json()
  })
}

export const getSubscribedFacebookPages = botId =>
  fetch(`${BASE_URL}/facebook/${botId}/all`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => response.json())
    .catch(error => alertError('Pages displaying error'))

export const setNewSubscribedFacebookPage = (botId, accessToken, body) =>
  fetch(`${BASE_URL}/facebook/${botId}/subscribePage`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify({ ...body, accessToken }),
  }).then(response => response.json())
    .then(response => customResponseParser(response))

export const editSubscribedFacebookPage = (botId, body) =>
  fetch(`${BASE_URL}/facebook/${botId}/update-page-configs`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'PUT',
    body: JSON.stringify(body),
  }).then(response => response.json())
    .then(response => customResponseParser(response))

export const unsubscribeFacebookPage = (botId, body) =>
  fetch(`${BASE_URL}/facebook/${botId}/unsubscribePage`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(body),
  }).then(response => response.json())
    .then(response => customResponseParser(response))

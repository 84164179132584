import React, { useState } from 'react'
import { MenuItem } from '@material-ui/core'
import ReactTooltip from 'react-tooltip'
import DetectableOverflow from 'react-detectable-overflow'

interface Props {
  page: any
  classes: any
}

export const TestWidgetMessengerItem: React.FC<Props> = ({ page, classes }) => {
  const [isOverflowed, setIsOverflowed]: [boolean, any] = useState(false)

  return (
    <MenuItem
      className={classes.listItem}
      key={page.pageId}
      component="a"
      href={`https://messenger.com/t/${page.pageId}`}
      target="_blank"
      data-tip={page.pageName}
      data-for={page.pageName}>
      <img src={page.pictureUrl} alt="page image" className={classes.image} />
      <DetectableOverflow onChange={setIsOverflowed}>
        {page.pageName}
      </DetectableOverflow>
      {isOverflowed && (
        <ReactTooltip
          className={classes.tooltip}
          effect="solid"
          place="left"
          type="dark"
          scrollHide={true}
          id={page.pageName}
        />
      )}
    </MenuItem>
  )
}

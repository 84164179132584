import styled from 'styled-components'
import { CORNFLOWER_BLUE, WHITE, ATHENS_GRAY_DARK, COMET_GREY } from '../../constants/colors'

const DateControl = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: flex-start;
  height: 115px;
`

const Frequency = styled.div`
  text-align: right;
  margin-top: 16px;
  width: max-content;
`

const FrequencyButton = styled.button<{ isSelected: boolean }>`
  background: ${({ isSelected }) => (isSelected ? CORNFLOWER_BLUE : WHITE)};
  border: 1px solid ${({ isSelected }) => (isSelected ? CORNFLOWER_BLUE : ATHENS_GRAY_DARK)};
  box-sizing: border-box;
  color: ${({ isSelected }) => (isSelected ? WHITE : COMET_GREY)};
  padding: 14px 18px;
  text-align: center;
  font-size: 14px;
  outline: none !important;
  &:first-of-type {
    border-radius: 10px 0 0 10px;
  }
  &:last-of-type {
    border-radius: 0 10px 10px 0;
  }
`

export { DateControl, Frequency, FrequencyButton }

import React, { useEffect, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import AttributesList from '../../uiKit/AttributesList/AttributesList'
import { AttributeIcon } from '../icons/AttributeIcon'
import { getAttributeColor } from '../../helpers/getAttributeColor'
import { createAttribute } from '../../tabs/settings/api/attributes'

import { styles } from './styles'
import {
  ARROW_DOWN_KEY,
  ARROW_LEFT_KEY,
  ARROW_RIGHT_KEY,
  ARROW_UP_KEY,
  BACKSPACE_KEY,
  DELETE_KEY,
  ENTER_KEY,
} from '../../constants/keyCodes'
import {
  ASTERISK,
  ATTRIBUTE_REGEX,
  ATTRIBUTE_REGEX_INCLUDE,
  ATTRIBUTE_REGEX_OR_OPEN_BRACKETS,
  CLOSE_BRACKET,
  CLOSE_PAIR_BRACKET,
  container,
  EMPTY_BRACKETS,
  NOT_ASTERISK_REGEX,
  OPEN_BRACKET,
  OPEN_PAIR_BRACKET,
} from './config'
import { usePrevious } from '../../hooks/usePrevious'
import { regexIndexOf } from '../../helpers/regexIndexOf'
import { FACEBOOK, TWILIO, WHATSAPP } from '../../constants/attributeTypes'
import { alertError } from '../../api'
import { handleSpace } from '../inputs/InputWithParamsAndLinks/helpers/handleSpace'
import xss from 'xss'

const InputWithParams = props => {
  const {
    classes,
    rtl,
    value,
    placeholder,
    onChange,
    disableBorderRadius,
    attributes,
    activeBotId,
    scrollBlock,
    singleLine,
    hoverButton,
    styles,
    saveTime,
    required,
    activeBot,
    twilioCredentials,
  } = props

  const [show, setShow] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [currentValue, setCurrentValue] = useState(null)
  const [searchValue, setSearchValue] = useState(EMPTY_BRACKETS)
  const [selectedAttribute, setSelectAttribute] = useState(1)
  const [caretPosition, setCaretPosition] = useState(0)
  const [touched, setTouched] = useState(false)
  const [focused, setFocused] = useState(false)
  const [notConnectedAttributes, setNotConnectedAttributes] = useState(false)

  const prevShow = usePrevious(show)
  const prevSaveTime = usePrevious(saveTime)

  const backdrop = useRef(null)
  const highlights = useRef(null)
  const textarea = useRef(null)
  const attributeIcon = useRef(null)
  const attributeList = useRef(null)

  const isFacebookConnected = activeBot?.messengerConfigs?.accessToken
  const isWhatsappConnected = activeBot?.dialog360WhatsAppConfigs?.apiKey
  const isTwilioConnected = twilioCredentials?.connected
  const isUseAttribute = show && !showSearch

  useEffect(() => {
    const attributesCopy = attributes
      .filter(({ type }) => {
        return (
          (type === FACEBOOK && !isFacebookConnected) ||
          (type === WHATSAPP && !isWhatsappConnected) ||
          (type === TWILIO && !isTwilioConnected)
        )
      })
      .map(({ name }) => {
        return `{{${name}}}`
      })

    setNotConnectedAttributes(attributesCopy)
  }, [attributes])

  //needs to highlight every time when save button has been clicked
  useEffect(() => {
    if (saveTime && prevSaveTime && saveTime !== prevSaveTime) {
      setTouched(true)
    }
  }, [saveTime])

  useEffect(() => {
    if (highlights?.current) {
      highlights.current.innerHTML = applyHighlights(textarea.current.value)
    }
  }, [value, attributes])

  useEffect(() => {
    if (scrollBlock?.current?.style?.overflowY) scrollBlock.current.style.overflowY = show ? 'hidden' : 'scroll'
    if (prevShow && show !== prevShow) { //NOSONAR
      textarea.current.focus()
      if (caretPosition) updateCursorPosition(caretPosition)
    }

    setSearchValue(EMPTY_BRACKETS)
    setSelectAttribute(1)
  }, [show])

  const handleScroll = event => {
    backdrop.current.scrollTop = event.target.scrollTop
    backdrop.current.scrollLeft = event.target.scrollLeft
    highlights.current.scrollLeft = event.target.scrollLeft
    highlights.current.scrollTop = event.target.scrollTop
  }

  const handleInput = event => {
    const text = xss(event.target.value, {onTag: () => ''})
    const selectionStart = textarea.current.selectionStart
    const selectionEnd = textarea.current.selectionEnd

    highlights.current.innerHTML = applyHighlights(text)

    //open attributes list when '{{' was typed
    if (text[selectionStart - 1] === OPEN_BRACKET && text[selectionStart - 2] === OPEN_BRACKET) {
      handleShowAttributes(false)
    }

    //close attributes list when '{' was removed
    if (text[selectionStart - 1] === OPEN_BRACKET && text[selectionStart - 2] !== OPEN_BRACKET) {
      setCaretPosition(selectionEnd)
      setShow(false)
    }

    //create new attribute when '}}' was typed
    if (
      text[selectionEnd - 1] === CLOSE_BRACKET &&
      text[selectionEnd - 2] === CLOSE_BRACKET &&
      text[selectionEnd - 3] !== CLOSE_BRACKET
    ) {
      const subString = text.substring(text.lastIndexOf(OPEN_PAIR_BRACKET) + 2, selectionEnd - 2)
      const isNewAttribute = !attributes.some(attribute => attribute.name === subString)
      setCaretPosition(selectionEnd)
      if (!subString.includes(CLOSE_PAIR_BRACKET) && isNewAttribute) {
        handleCreateAttribute(subString)
      } else {
        setShow(false)
      }
    }

    handleSearch(text)
  }

  const handleSearch = text => {
    const caretPosition = textarea.current.selectionStart
    const noAttributesText = text?.replace(ATTRIBUTE_REGEX, EMPTY_BRACKETS)
    const arrowIndex = noAttributesText.indexOf(OPEN_PAIR_BRACKET)
    const textStart = text.substring(0, caretPosition)
    const noAttributeTextStartLength = textStart
      .replace(ATTRIBUTE_REGEX, EMPTY_BRACKETS)
      .substring(0, caretPosition).length
    const newSearchValue = noAttributesText.substring(
      arrowIndex,
      caretPosition - (textStart.length - noAttributeTextStartLength),
    )

    if (newSearchValue.includes(OPEN_PAIR_BRACKET) && !newSearchValue.includes(CLOSE_BRACKET)) {
      setSearchValue(newSearchValue.replace(OPEN_PAIR_BRACKET, EMPTY_BRACKETS))
    }
  }

  const handleArrowDown = event => {
    const key = event.keyCode || event.charCode

    if (key === ARROW_DOWN_KEY) {
      event.preventDefault()

      if (show) {
        updateAttributeListScroll(ARROW_DOWN_KEY)
      }
    }
  }

  const handleArrowUp = event => {
    const key = event.keyCode || event.charCode

    if (key === ARROW_UP_KEY) {
      event.preventDefault()

      if (show) {
        updateAttributeListScroll(ARROW_UP_KEY)
      }
    }
  }
  const handleEnter = event => {
    const key = event.keyCode || event.charCode

    if (key === ENTER_KEY && (show || singleLine)) {
      event.preventDefault()
      const selectedValue = attributeList?.current?.childNodes[selectedAttribute]?.innerText || searchValue
      if (selectedValue) {
        addParam(selectedValue)
      }
    }
  }

  const handleLeftArrow = event => {
    const key = event.keyCode || event.charCode
    const prevChar = event.target.value && event.target.value[textarea.current.selectionStart - 1]

    if (key === ARROW_LEFT_KEY && show) {
      event.preventDefault()
    }

    if (key === ARROW_LEFT_KEY && prevChar === CLOSE_BRACKET) {
      event.preventDefault()

      const closestBracketsIndex = event.target.value
        .substring(0, textarea.current.selectionStart)
        .lastIndexOf(OPEN_PAIR_BRACKET)

      updateCursorPosition(closestBracketsIndex)
    }
  }

  const handleRightArrow = event => {
    const key = event.keyCode || event.charCode
    const nextChar = event.target.value && event.target.value[textarea.current.selectionStart + 1]

    if (key === ARROW_RIGHT_KEY && show) {
      event.preventDefault()
    }

    if (key === ARROW_RIGHT_KEY && nextChar === OPEN_BRACKET) {
      event.preventDefault()

      const stringStart = event.target.value.substring(0, textarea.current.selectionStart)

      const closestBracketsIndex =
        event.target.value
          .substring(textarea.current.selectionStart, event.target.value.length)
          .indexOf(CLOSE_PAIR_BRACKET) +
        stringStart.length +
        2

      updateCursorPosition(closestBracketsIndex)
    }
  }

  const onKeyDown = event => {
    handleSpace(event, isUseAttribute)

    handleArrowDown(event)

    handleArrowUp(event)

    handleEnter(event)

    handleLeftArrow(event)

    handleRightArrow(event)

    setCurrentValue(event.target.value)
  }

  const updateAttributeListScroll = direction => {
    let selectedChildNodeIndex
    const childNodes = attributeList?.current?.childNodes
    const directionStep = direction === ARROW_UP_KEY ? -1 : 1
    const childNodesLength = childNodes?.length - 1

    //change index of highlighted item
    if (childNodes[selectedAttribute + directionStep]) {
      selectedChildNodeIndex = selectedAttribute + directionStep
    } else {
      selectedChildNodeIndex = direction === ARROW_UP_KEY ? childNodesLength : 1
    }

    //make one step ahead when childNode is not item
    if (childNodes[selectedChildNodeIndex].nodeName !== 'DIV') {
      selectedChildNodeIndex =
        selectedChildNodeIndex + directionStep < 1 ? childNodesLength : selectedChildNodeIndex + directionStep
    }

    const selectedChildNode = childNodes[selectedChildNodeIndex]

    selectedChildNode.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    })
    setSelectAttribute(selectedChildNodeIndex)
  }

  //function to remove whole attribute by 'delete' or 'backspace'
  const onKeyUp = event => {
    const key = event.keyCode || event.charCode
    const position = textarea.current.selectionStart
    const placeholder = ATTRIBUTE_REGEX

    if (key === DELETE_KEY || key === BACKSPACE_KEY) {
      for (let match; (match = placeholder.exec(currentValue)) !== null;) {
        if (match.index < position + 1 && placeholder.lastIndex > position) {
          handleChange(
            currentValue.substr(0, match.index) + currentValue.substring(placeholder.lastIndex, currentValue.length),
          )

          const caretPosition = currentValue.substr(0, match.index).length

          //need this to keep cursor position
          setTimeout(() => updateCursorPosition(caretPosition), 0)
        }
      }
    }
  }

  const addParam = attributeName => {
    const safeAttribute = xss(attributeName, {onTag: () => ''})
    const position = textarea.current.selectionStart
    const stringStart = value.slice(0, position)
    const lastBracketIndex = stringStart.lastIndexOf(OPEN_PAIR_BRACKET)
    const start = showSearch ? stringStart : stringStart.slice(0, lastBracketIndex)
    const end = value.slice(position)
    const text = `${start}{{${safeAttribute}}}${end}`
    const caretPosition = position + (safeAttribute?.length - searchValue.length) + (showSearch ? 4 : 2)

    setCaretPosition(caretPosition)

    handleChange(text)
    setShow(false)
  }

  const handleShowAttributes = focus => {
    setShow(true)
    setShowSearch(focus)
  }

  const handleCreateAttribute = attributeName => {
    createAttribute(activeBotId, { name: attributeName }).then(() => setShow(false))
  }

  const getPopupPosition = () => {
    const buttonPos = attributeIcon?.current?.getBoundingClientRect()
    const highlightsPos = Object.values(highlights?.current?.children || {})
      ?.find(({ nodeName }) => nodeName === 'MOCK')
      ?.getBoundingClientRect()

    return getPopupPositionByElement(showSearch ? buttonPos : highlightsPos)
  }

  const getPopupPositionByElement = element => {
    const absoluteTop = element?.top + element?.height
    const absoluteRight = element?.left + element?.width
    const isEnoughSpaceHeight = absoluteTop + container.height > window.innerHeight
    const isEnoughSpaceWidth = absoluteRight + container.width < window.innerWidth

    const top = absoluteTop - (isEnoughSpaceHeight && container.height) + 'px'

    const left = absoluteRight - (!isEnoughSpaceWidth && container.width) + 'px'

    return { top, left }
  }

  const handleCloseAttributeList = () => {
    const text = value?.split(ATTRIBUTE_REGEX_INCLUDE)?.map(removeExtraBrackets)?.join(EMPTY_BRACKETS) || ''

    handleChange(text)
    setShow(false)
  }

  const removeExtraBrackets = str => {
    if (!str.endsWith(CLOSE_PAIR_BRACKET)) {
      return str.replace(`{{${searchValue}`, EMPTY_BRACKETS)
    }
    return str
  }

  const updateCursorPosition = caretPosition => {
    textarea.current.selectionStart = caretPosition
    textarea.current.selectionEnd = caretPosition
  }

  const handleSkipAttribute = () => { //NOSONAR
    const updatedValue = value?.replaceAll(ATTRIBUTE_REGEX_INCLUDE, match => {
      return match.replace(/[^{}]/g, ASTERISK)
    })

    const prevChar = updatedValue
      && textarea?.current?.selectionStart ? updatedValue[textarea.current.selectionStart - 1] : ''
    const nextChar = updatedValue && updatedValue[textarea?.current?.selectionStart]

    if (
      [ASTERISK, CLOSE_BRACKET, OPEN_BRACKET].includes(prevChar) &&
      [ASTERISK, CLOSE_BRACKET, OPEN_BRACKET].includes(nextChar)
    ) {
      const endText = updatedValue.substring(textarea?.current?.selectionStart, updatedValue.length)
      let caretPosition = updatedValue.substring(0, textarea?.current?.selectionStart).length

      if (regexIndexOf(endText, NOT_ASTERISK_REGEX, 0) > 0 || (prevChar === ASTERISK && nextChar === CLOSE_BRACKET)) {
        caretPosition += regexIndexOf(endText, NOT_ASTERISK_REGEX, 0) + 2
      } else if (prevChar === OPEN_BRACKET && nextChar === OPEN_BRACKET) {
        caretPosition += regexIndexOf(endText, NOT_ASTERISK_REGEX, 2) + 2
      } else if (prevChar === CLOSE_BRACKET && nextChar === CLOSE_BRACKET) {
        caretPosition += regexIndexOf(endText, NOT_ASTERISK_REGEX, 0) + 1
      } else if (prevChar === CLOSE_BRACKET && nextChar === OPEN_BRACKET) {
        caretPosition = textarea.current.selectionStart
      } else {
        caretPosition = updatedValue.length
      }

      updateCursorPosition(caretPosition)
    }
  }

  const applyHighlights = text => {
    return text?.replace(/\n$/g, '\n\n')?.replace(ATTRIBUTE_REGEX_OR_OPEN_BRACKETS, onReplaceAttribute)
  }

  const onReplaceAttribute = match => {
    const matchCopy = match?.replace(/{{/g, '')?.replace(/}}/g, '')
    const currentAttribute = attributes?.find(attribute => attribute.name === matchCopy)
    const openBracketsStyle = `background: ${getAttributeColor(
      currentAttribute?.type,
    )}; color:transparent; border-radius: 50px 0 0 50px;`
    const attributeStyle = `background: ${getAttributeColor(currentAttribute?.type)}; color:#ffffff;`
    const closeBracketsStyle = `background: ${getAttributeColor(
      currentAttribute?.type,
    )}; color:transparent; border-radius: 0 50px 50px 0;`

    return match.length > 2
      ? // eslint-disable-next-line max-len
      `<span style="${openBracketsStyle}">{{</span><span style="${attributeStyle}">${matchCopy}</span><span style="${closeBracketsStyle}">}}</span>`
      : `<mock>${match}</mock>`
  }

  const handleBlur = () => {
    setCaretPosition(textarea.current.selectionStart)
    setFocused(false)
  }

  const handleChange = value => {
    const safeValue = xss(value, {onTag: () => ''})
    let valueCopy = safeValue
    notConnectedAttributes.forEach(att => {
      const found = safeValue.match(att) && safeValue.match(att)[0]
      if (found) {
        alertError('Attribute with this channel is not connected to bot instance')
      }
      return (valueCopy = valueCopy.replace(found, ''))
    })

    setTouched(true)
    onChange(valueCopy)
  }

  const handleFocus = () => {
    setFocused(true)
    setTouched(true)
  }

  const handlePaste = (e) => {
    if (isUseAttribute) e.preventDefault()
  }

  return (
    <div className={classes.container} style={{ height: styles?.height || '120px', padding: 0 }}>
      <div
        className={classes.backdrop}
        style={{
          borderRadius: disableBorderRadius && '0px',
          overflowY: singleLine && 'hidden',
          ...styles,
        }}
        ref={backdrop}>
        <div
          className={singleLine ? classes.highlightsInput : classes.highlights}
          ref={highlights}
          dir={rtl ? 'rtl' : 'ltr'}
          style={{
            textAlign: rtl && 'right',
            unicodeBidi: rtl && 'bidi-override',
          }}
        />
      </div>
      <textarea
        className={singleLine ? classes.input : classes.textarea}
        dir={rtl ? 'rtl' : 'ltr'}
        style={{
          borderRadius: disableBorderRadius && '0px',
          unicodeBidi: rtl && 'bidi-override',
          border: required && touched && !value && !focused && !show && '1px solid red',
          ...styles,
        }}
        value={value}
        placeholder={placeholder}
        onChange={e => handleChange(e.target.value)}
        onScroll={handleScroll}
        onInput={handleInput}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onClick={handleSkipAttribute}
        onBlur={handleBlur}
        ref={textarea}
        onFocus={handleFocus}
        onPaste={handlePaste}
      />
      <AttributesList
        addParam={addParam}
        show={show}
        onClose={handleCloseAttributeList}
        position={getPopupPosition()}
        showSearch={showSearch}
        searchValue={searchValue}
        listRef={attributeList}
        selectedAttribute={selectedAttribute}
        onKeyDown={event => onKeyDown(event)}
        updateSelectedAttribute={setSelectAttribute}
      />
      <div className={classes.iconsWrap}>
        {hoverButton}
        <span
          className={classes.icon}
          style={{ visibility: show && showSearch && 'visible' }}
          onClick={() => handleShowAttributes(true)}
          onKeyDown={event => onKeyDown(event)}
          tabIndex={'0'}
          ref={attributeIcon}>
          <AttributeIcon />
        </span>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  attributes: state.attributes,
  activeBotId: state.activeBot?.id,
  activeBot: state.activeBot,
  twilioCredentials: state.twilioCredentials,
})

export default withStyles(styles)(connect(mapStateToProps)(InputWithParams))

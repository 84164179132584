import React from 'react'

import { DoneIcon } from 'uiKit/icons/Icons'
import * as S from './TrainButton.style'

interface Props {
  onClick: () => void
  isTrained: boolean
}

export const TrainButton: React.FC<Props> = ({ onClick, isTrained }) => {
  return (
    <S.TrainButton onClick={onClick} isTrained={isTrained} disabled={isTrained}>
      {isTrained ? <DoneIcon /> : 'Train'}
    </S.TrainButton>
  )
}
